import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Stark({ styles, ...props }) {
  const Elements = useComponents()

  const button_color = styles.forElement("button").color
  const placeholder_color = styles.forElement("input").color

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page", { backgroundColor: "#ffffff" })}
    >
      <div
        className="formkit-container"
        style={{
          backgroundColor: styles.forElement("background").backgroundColor
        }}
      >
        <div
          className="formkit-image-container"
          style={styles.forElement("background")}
        />
        <div
          className="formkit-form-content"
          style={styles.forElement("form", {
            backgroundColor: "#ffffff"
          })}
        >
          <Elements.Heading
            className="formkit-header"
            name="header"
            defaults={{ content: "Enter a catchy page headline" }}
          />
          <Elements.Region name="content" className="formkit-content">
            <Elements.Content
              defaults={{
                content: "Provide some more detail in this subtitle"
              }}
            />
          </Elements.Region>
          <Elements.Form style={{ "--placeholder": placeholder_color }}>
            <Elements.Errors />
            <Elements.CustomFields>
              <Elements.AddFieldButton />
              <div className="formkit-button-wrapper">
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Subscribe" }}
                />
                <div
                  className="formkit-arrow"
                  style={{ "--color": button_color }}
                >
                  <span className="formkit-arrow-left" />
                  <span className="formkit-arrow-right" />
                </div>
              </div>
            </Elements.CustomFields>
          </Elements.Form>
          <Elements.Content
            name="disclaimer"
            defaults={{
              content: "We respect your privacy. Unsubscribe at any time."
            }}
          />
          <Elements.BuiltWith background="form" />
        </div>
      </div>
    </Elements.LandingPage>
  )
}

Stark.style = "landing_page"
Stark.categories = ["Newsletter", "Profile", "Waitlist"]
Stark.thumbnail = ""
Stark.preview = "https://demo.ck.page/stark"
Stark.fields = [
  {
    name: "first_name",
    label: "First name"
  },
  {
    name: "email_address",
    label: "Email address"
  }
]

export default createTemplate(Stark, { name: "Stark" })
